import { Observable, catchError, map, of } from 'rxjs';
import { useNetworkService } from './NetworkService';
import { TokenInfo, USER_TOKEN_KEY } from '../../models/User';
import { useLocalStorage } from 'usehooks-ts';

export const useUserWalletService = () => {
  const { getData, postData } = useNetworkService();
  const initialUserToken = localStorage.getItem(USER_TOKEN_KEY);

  const [userToken, setUserToken] = useLocalStorage<TokenInfo | null>(
    USER_TOKEN_KEY,
    initialUserToken ? (JSON.parse(initialUserToken) as TokenInfo) : null,
  );

  const getUserToken = () => {
    return userToken;
  };

  const getAdminWallet = (): Observable<string> => {
    return getData(`user-wallet/admin`);
  };

  const postNonce = (publicAddress: string): Observable<number | string> => {
    removeUserToken();
    return postData(`user-wallet/nonce`, { publicAddress });
  };

  const postLogin = (
    signature: string,
    publicAddress: string,
    network: string,
  ): Observable<TokenInfo> => {
    return postData(`user-wallet/login`, { signature, publicAddress, network }).pipe(
      map((token) => {
        if (token) {
          setUserToken(token);
        }
        return token;
      }),
    );
  };

  const refreshToken = (): Observable<TokenInfo> => {
    const refreshToken = userToken && userToken.refresh_token ? userToken.refresh_token : null;
    return postData(`user-wallet/login/refresh`, { refreshToken }).pipe(
      map((token) => {
        if (token) {
          setUserToken(token);
        }
        return token;
      }),
      catchError((error) => {
        removeUserToken();
        return of(error);
      }),
    );
  };

  const postLogout = () => {
    const refreshToken = userToken && userToken.refresh_token ? userToken.refresh_token : null;
    postData(`user-wallet/logout`, { refreshToken }).subscribe(
      () => {
          removeUserToken();
      },
      (error) => {
        const logoutErrMsg = 'An error occured : 204 NO_CONTENT';
        const logoutErrUrl = 'user-wallet/logout';
        if (
          error?.response?.data?.details?.[0] === logoutErrMsg &&
          error?.config?.url === logoutErrUrl
        ) {
          removeUserToken();
        }
      },
    );
  };

  const removeUserToken = () => {
    setUserToken(null);
    localStorage.removeItem(USER_TOKEN_KEY);
  };

  return {
    getAdminWallet,
    postNonce,
    postLogin,
    refreshToken,
    postLogout,
    getUserToken,
    removeUserToken,
  };
};
