import { ThemeOptions, createTheme } from '@mui/material/styles';
import { Theme } from './interfaces';

const getTheme = () =>
  createTheme({
    trilemma: {
      palette: {
        background: '#fff',
        text: '#000',
        secondaryText: '#0c0c0cb3',
        menuItemActiveBackground: 'rgba(12, 12, 12, 0.1)',
        boxShadowBig: '0px 100px 44px -80px rgba(31, 31, 30, 0.2)',
        blueSectionLight: '#b5bee8',
        blueSectionUltraLight: '#f5f6f5',
        blueSectionButton: '#647cfa',
      },
      navbar: {
        backdropFilter: 'blur(6px)',
        backgroundColor: 'rgba(255,255,255,0.7)',
        transition: 'background-color .5s ease-in'
      },
      belloRegularFontFamily: 'BelloRegular',
      headerMaxWidth: '792px',
      pageHeaderMaxWidth: '1231px',
    },
    palette: {
      primary: {
        main: '#000',
        contrastText: '#fff',
      },
      secondary: {
        main: '#000',
        contrastText: '#fff',
      },
      text: {
        primary: '#000',
        secondary: '#0c0c0cb3',
      },
    },
    typography: {
      fontFamily: 'TrilemmaRegular, Arial',
      h1: {
        fontSize: 72,
        fontWeight: 700,
        marginTop: '0.67rem',
        marginBottom: '0.67rem',
        '@media (max-width:1280px)': {
          fontSize: 50,
        },
      },
      h2: {
        fontSize: 48,
        fontWeight: 700,
        '@media (max-width:1280px)': {
          fontSize: 36,
        },
      },
      h3: {
        fontSize: 36,
        fontWeight: 700,
        marginTop: '0.3rem',
        marginBottom: '0.3rem',
        '@media (max-width:1280px)': {
          fontSize: 30,
        },
      },
      h4: {
        fontSize: 30,
        fontWeight: 700,
        marginTop: '0.3rem',
        marginBottom: '0.3rem',
        '@media (max-width:1280px)': {
          fontSize: 24,
        },
      },
      h5: {
        fontSize: 24,
        '@media (max-width:1280px)': {
          fontSize: 21,
        },
      },
      h6: {
        fontSize: 20,
        '@media (max-width:1280px)': {
          fontSize: 20,
        },
      },
      body1: {
        fontSize: 16,
        lineHeight: '18px',
      },
      button: {
        fontFamily: 'TrilemmaRegular',
        fontSize: 16,
        textTransform: 'none',
      },
    },
  } as ThemeOptions) as Theme;

export default getTheme;
