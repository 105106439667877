import { useEffect } from 'react';
import axios from 'axios';
import { useUserWalletService } from '../shared/services/business/UserWalletService';
import axiosSecureInstance from '../helpers/axiosSecure';
import { useLocalStorage } from 'usehooks-ts';
import { TokenInfo, USER_TOKEN_KEY } from '../shared/models/User';

export const AxiosSecureProvider = ({ children }: any) => {
  const { refreshToken, getUserToken } = useUserWalletService();
  const [userToken] = useLocalStorage<TokenInfo | null>(USER_TOKEN_KEY, getUserToken());

  const isExpired = () => {
    if (userToken != null && userToken.expires_in) {
      const current = new Date();
      const expireDate = new Date(userToken.expires_in * 1000);
      return current >= expireDate;
    }
    return false;
  };

  useEffect(() => {
    axiosSecureInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error?.response?.status === 401 && userToken != null && isExpired()) {
          try {
            const response = await refreshToken().toPromise();
            error.config.headers.Authorization = `Bearer ${response?.access_token}`;
            return axios(error.config);
          } catch (e) {
            return Promise.reject(error);
          }
        } else {
          return Promise.reject(error);
        }
      },
    );
  }, []);

  return children;
};
