import React, { Suspense, lazy } from 'react';
import './App.css';
import { getTheme } from './styles';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AxiosSecureProvider } from './utils/axiosSecureProvider';
import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/fr';

const Layout = lazy(() => import('./pages/Layout'));
const GlobalSnackbar = lazy(() => import('./components/common/GlobalSnackbar'));
dayjs.extend(relativeTime);
dayjs.locale('fr');

function App() {
  const theme = getTheme();
  console.log(process.env.NODE_ENV);
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AxiosSecureProvider>
          <Suspense fallback={<span>Loading...</span>}>
            <GlobalSnackbar>
              <Layout />
            </GlobalSnackbar>
          </Suspense>
        </AxiosSecureProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
