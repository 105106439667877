export const USER_TOKEN_KEY = 'user_token';
export interface TokenInfo {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  scope: string;
  session_state: string;
  token_type: string;
}
