import axios from 'axios';
import { TokenInfo, USER_TOKEN_KEY } from '../shared/models/User';

const axiosSecureInstance = axios.create({
  baseURL: location.protocol + '//' + location.host + '/' + process.env.REACT_APP_SECURE_BASE_URL,
});

axiosSecureInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem(USER_TOKEN_KEY);
  const userToken = token ? (JSON.parse(token) as TokenInfo) : null;
  config.headers.Authorization = `Bearer ${userToken?.access_token}`;
  return config;
});

export default axiosSecureInstance;
